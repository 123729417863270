.card-container.card {
    width: 350px;
    padding: 40px 40px;
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
}

.card {
    background-color: #F7F7F7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

#logo {
    max-width: 250px;
    max-height: 150px;
    margin: 5vh auto 10px;
    display: block;
}

#divLogo {
    border-bottom: 1px solid rgb(217, 218, 220);
}

#hash {
    margin-bottom: 10px;
}

.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: #74ac2d;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px #74ac2d;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px #74ac2d;
}

.btn-signin {
    background-color: #74ac2d;
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    border-radius: 500px;
    -moz-border-radius: 500px;
    -webkit-border-radius: 500px;
    letter-spacing:1.5px;
    text-transform:uppercase;
    color: black;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn-signin:hover {
    opacity: 70%;
}