.jinglesPage {
  color: white;
}

.nameField div input {
  color: white;
}

.textField div textarea {
  color: white;
}

.nameField {
  border: 1px solid white !important;
  border-radius: 5px;
}

.textField {
  border: 1px solid white !important;
  border-radius: 5px;
}
