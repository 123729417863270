.musicsPage {
    color: white;
    height: calc(100vh - 145px) !important;
    overflow: auto !important;
    top:30px
}

.playButton {
    color: #74ac2d;
}

.tableBody {
    background-color: black;
}

.playButton:hover {
    filter: brightness(150%);
}

.loaderIcon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}