#player-bar {
  bottom: 0;
  position: fixed;
  width: 100%;
  background: #2b2b2b;
  overflow: hidden;
  height: 75px;
  display:flex;
  flex-direction: row;
}

.progress {
  cursor: pointer;
  margin-top:10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 5px;
}

.progress-bar {
  height: 5px;
  width:98%;
  background-color: #74ac2d;
  border-radius: 6px;
}

#volume {
  width: 40%;
  color: #74ac2d;
}

#musicTime {
  width: 60%;
  color: #74ac2d;
}

#button-control {
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  height: 70%;
  width: 100%;
  text-align: justify;
  padding: 0px 15%;
}

.tempo {
  color: #c5c4c1;
  margin: 0 10px;
  user-select: none;
  font-size: 12px;
}

.control, .control-play {
  color: #c5c4c1;
  cursor: pointer;
}

.control-play {
  font-size:30px;
}

.control {
  font-size:20px;
}

input[type=range] {
  cursor: pointer;
}

.volume-icon, #fila {
  color: #c5c4c1;
  margin-bottom: 4px;
}

#fila {
  margin-right: 10px;
}

.volume-icon {
  margin-right:20px;
}

#change-volume, #fila {
  cursor: pointer;
}

.avaliacao-icon {
  cursor: pointer;
  text-align: center;
  color: #c5c4c1;
}

#divMusica {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
}

#cantor {
  color: #c5c4c1;
  user-select: none;
}

#musica {
  color: #c5c4c1;
  user-select: none;
}

.controle-volume {
  width: 30%;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.infos-artista {
  background-color: #2b2b2b;
  width:32%;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.center {
  width: calc(100% - 62%);
}

#miniatura {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2b2b2b;  
  height: 100%;
  box-sizing: border-box;
  width: 100px;
}

#thumbnail {
  width: 60px;
  height: 60px;
}

#info-music {
  background-color: #2b2b2b;  
  height: 100%;
  width: calc( 47% );
  padding: 10px;
  box-sizing: border-box;
}

#musica {
  margin: -1px;
  margin-top: 10px;
  font-weight: bold;
}

#avaliacao {
  background-color: #2b2b2b;
  width: calc( 53% - 100px );
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

#progresso {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 13px;
}

.progress {
  margin-top: 1px;
}

.blockClick {
  pointer-events: none;
}

.active {
  color: #74ac2d;
}

.scroll-container-musica {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.scroll-container-cantor {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.scrolling-text-musica:hover {
  display: inline-block;
  animation: marquee 7s linear infinite;
}

.scrolling-text-cantor:hover {
  display: inline-block;
  animation: marquee2 7s linear infinite ;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}