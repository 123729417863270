.playlistsPage {
    color: white;
}

.playlistsImage {
    width: 226.33px;
    height: 226.33px;
    border-radius: 5px;
    margin-bottom: 11px;
    margin-top: 30px;
    margin-right: 15px;
}

.playlistsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 15px;
}

.enterIconWraper {
    width: 226.33px;
    height: 226.33px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -262px;
    position: absolute;
}

.enterIconWraper:hover {
    opacity: 1;
    background-color: rgba(0,0,0,.3);
    cursor: pointer;
}

.enterIcon {
    font-size: 38px;
    color: white;
}

.backButton {
    color: white;
    font-size: 31px;
    margin-right: 7%;
}

.backButton:hover {
    cursor: pointer;
}

.pageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchField {
    border-bottom: 1px solid white !important;
}

.searchField div input {
    color: white;
}

.loaderIcon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

