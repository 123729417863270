.homePage {
    color: #58585A;
}

#infos {
    background-color: #111;
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
    display: flex;
    margin-left: 10px;
}

.imgPlaylist {
    width: 200px;
    height: 200px;
    background-color: #58585A;
    border-radius: 5px 5px 0 0;
}

.descImage {
    text-align: center;
    font-size: 16px;
    width: 200px;
    height: 90px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #111;
    padding-top: 30px;
    line-height:19.5px;
    user-select: none;
    border-radius: 0 0 5px 5px;
}

.playListInfo {
    margin: 10px;
}

#allPlaylists {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 5px;
}

.icon {
    color: #58585A;
}

.enterIconWraperHome {
    width: 200px;
    height: 200px;
    opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -290px;
}

.enterIconWraperHome:hover {
    opacity: 1;
    background-color: rgba(0,0,0,.3);
    cursor: pointer;
}

.enterIconHome {
    font-size: 38px;
    color: white;
}

.loaderIcon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.beatLoaderIcon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;   
}