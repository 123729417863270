* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.button:hover {
  filter: brightness(90%);
}

body {
  background-color: black;
}

.pageContainerMenuOpen {
  position: absolute;
  width: calc(100% - 219.99px);
  left: 219.99px;
  height: calc(100vh - 75px);
  position: fixed;
  top: 0px;
  overflow: auto;
  transition-duration: 300ms;
}

.pageContainerMenuClosed {
  position: absolute;
  width: calc(100% - 91.96px);
  left: 91.96px;
  height: calc(100vh - 120px);
  position: fixed;
  top: 26px;
  overflow: auto;
  transition-duration: 300ms;
}

::-webkit-scrollbar {
  display: none;
}

