#header {
  position: fixed;
  width: 220px;
}

#header .pro-sidebar {
  height: calc(100vh - 75px);
}

#header .closemenu {
  color: #c5c4c0;
  position: absolute;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 59px;
  cursor: pointer;
  transition-duration: 1500ms;
  transition-delay: 500ms;
} 

#FaArrowCircleRight {
  position: absolute;
  right: 0px;
  z-index: 9999;
  transition-duration: 600ms;
}

#FaArrowCircleLeft {
  position: relative;
  top: 67px;
  right: -16px;
  z-index: 9999;
  transition-duration: 600ms;
}

#pro-sidebar-content {
  scroll-behavior: auto;
}

.pro-sidebar-icon {
  font-size: 22px;
}

.pro-sidebar-menuItem:hover {
  background-color: #56742f;
}

#pro-sidebar-menuLogout:hover {
  background-color: #780000;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: black;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logoContainer p {
  font-size: 20px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: white;
  margin: 7px 0px;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #c5c4c0;
  color: white;
  border-radius: 3px;
  box-shadow: 2px 3px 5px rgb(0, 0, 0);
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: white;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #74ac2d 0%, #74ac2d 100%);
}

.active .pro-inner-item span.pro-item-content {
  color: black;
}

#header .logo {
  padding: 20px;
}

#pro-sidebar-footer {
  position: absolute;
  bottom: 0px;
}

#pro-sidebar-menuSair {
  padding-top: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

#bigLogo {
  height: 50%;
  width: 50%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.yesButton {
  margin-right: 10px;
}

svg {
  color: black;
}