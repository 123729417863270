.musicsPageAll {
    color: white;
    height: calc(100vh - 101px) !important;
    overflow: auto !important;
}

.playButton {
    color: #74ac2d;
}

.tableBody {
    background-color: black;
}

.playButton:hover {
    filter: brightness(150%);
}

.searchField {
    border-bottom: 1px solid white !important;
}

.searchField div input {
    color: white;
}

.loaderIcon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiPaginationItem-root, .MuiSvgIcon-root {
    color: white !important;
}

.MuiPaginationItem-root:hover {
    background-color: #90bd57 !important;
}