.configsPage {
  color: white;
}

.switch > span.MuiSwitch-track {
  background-color: #74ac2d;
}

.logoutButton {
  width: 100px;
}